<template>
    <div>
        <user-route-tabs :user-id="userId"></user-route-tabs>
        <user-address-form :user-id="userId"></user-address-form>
    </div>
</template>

<script>
import UserAddressForm from '@/components/pages/userAddresses/Form'
import UserRouteTabs from '@/components/pages/users/RouteTabs'
import dirtyForm from '@/components/mixins/dirtyForm'

export default {
    components: { UserAddressForm, UserRouteTabs },
    mixins: [dirtyForm],

    props: {
        userId: {
            type: [String, Number],
            default: null,
        },
    },
}
</script>
