<template>
    <div>
        <user-route-tabs :user-id="userId"></user-route-tabs>
        <user-address-form
            :user-id="userId"
            :entity-id="addressId"
        ></user-address-form>
    </div>
</template>

<script>
import UserRouteTabs from '@/components/pages/users/RouteTabs'
import UserAddressForm from '@/components/pages/userAddresses/Form'
import dirtyForm from '@/components/mixins/dirtyForm'

export default {
    components: { UserAddressForm, UserRouteTabs },

    mixins: [dirtyForm],

    props: {
        addressId: {
            type: [String, Number],
            default: null,
        },
        userId: {
            type: [String, Number],
            default: null,
        },
    },
}
</script>
