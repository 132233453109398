<template>
    <div>
        <base-form
            ref="form"
            v-model="form"
            :api="api"
            :mapper="mapper"
            :entity-id="entityId"
            :title="title"
            :disabled="disabled"
            @created="created"
            @updated="updated"
            @cancelled="cancelled"
            @deleted="deleted"
        >
            <addresses-form-part v-model="form"></addresses-form-part>
        </base-form>
    </div>
</template>

<script>
import routeType from '@/router/routeType'
import RestApiType from '@/api/RestApiType'
import MapperType from '@/services/mapper/MapperType'
import RestApiCollection from '@/api/RestApiCollection'
import FormBase from '@/components/mixins/FormBase'
import AddressesFormPart from '@/components/pages/addresses/AddressesFormPart'

export default {
    components: { AddressesFormPart },
    mixins: [FormBase],
    props: {
        userId: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            api: RestApiType.USER_ADDRESSES,
            mapper: MapperType.USER_ADDRESSES_FORM,
            form: {
                name: null,
                invoiceName: null,
                phone: null,
                longitude: null,
                latitude: null,
                fullAddress: null,
                houseNumber: null,
                street: null,
                town: null,
                zipCode: null,
                sublocality: null,
                administrativeAreaLevel1: null,
                administrativeAreaLevel2: null,
                administrativeAreaLevel3: null,
                administrativeAreaLevel4: null,
                country: null,
                countryCode: null,
                subAddress: null,
                comment: null,
            },
            entity: 'labels.addresses',
            editRoute: routeType.USER_ADDRESS_EDIT,
            listRoute: routeType.USER_ADDRESS_LIST,
        }
    },
    watch: {
        userId: {
            immediate: true,
            handler(userId) {
                this.form.userProfile = RestApiCollection.get(
                    RestApiType.USERS
                ).getItemIri(userId)
            },
        },
    },
    methods: {
        editRedirectParams(data) {
            return {
                addressId: data.id,
                userId: this.userId,
            }
        },
        listRedirectParams() {
            return { userId: this.userId }
        },
    },
}
</script>
